import axios, { AxiosError, AxiosInstance } from "axios";
import axiosRetry from "axios-retry";
import { BuildGuide } from "../types/build-guide";
import { BuildOverview } from "../types/build-overview";

const BackendURL = process.env.REACT_APP_BACKEND_URL;

const client = axios.create({ 
    timeout: 20000
})
axiosRetry(client, {
    retries: 10,
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return 2000;
    },
    retryCondition: (error: AxiosError) => {
        let statusCode = error.response?.status
        return statusCode != undefined && statusCode >= 500;
    }
})

async function ListBuildOverviews(): Promise<BuildOverview[]> {
    let url = `${BackendURL}/BuildOverviews`
    try {
        let response = await client.get(url, {
            validateStatus: function (status) {
                return status < 500;
            }
        });
        return response.data.map((record: any) => {
            return new BuildOverview(record);
        });
    } catch (err) {
        throw Error("failed to list build overviews: " + err)
    }
}

function marshallBuildOverview(record: any): BuildOverview {
    var overview: BuildOverview = {
        id: record.id,
        name: record.name,
        tank: record.tank,
        filter: record.filter,
        heater: record.heater,
        price: record.price,
        preview_url: record.preview_url,
        guide_id: record.guide_id
    }
    return overview
}

async function GetBuildGuide(id: string): Promise<BuildGuide> {
    let url = `${BackendURL}/BuildGuides/${id}`
    try {
        let response = await client.get(url, {
            validateStatus: function (status) {
                return status < 400;
            }
        });
        return new BuildGuide(response.data);
    } catch (err) {
        throw Error("failed to get build guide: " + err)
    }
}

export { ListBuildOverviews, GetBuildGuide };