import { AffiliateProvider, CreateAffiliateProvider } from './affiliate-provider';

enum PartType {
    Tank = "tank",
    Heater = "heater",
    Filter = "filter",
    AirPump = "air_pump",
    Light = "light"
};

class Part {
    type: PartType
    preview_url: string
    name: string
    affiliate_link: string
    affiliate_provider: AffiliateProvider
    price: number
    
    constructor(response: {type: PartType, preview_url: string, name: string, affiliate_link: string, affiliate_provider: string, price: number}) {
        this.type = response.type;
        this.preview_url = response.preview_url;
        this.name = response.name;
        this.affiliate_link = response.affiliate_link;
        this.affiliate_provider = CreateAffiliateProvider(response.affiliate_provider)
        this.price = response.price
    }
}

type Description = {
    parts: Map<string, string>
}

class BuildGuide {
    id: string
    name: string
    parts: Part[]
    description: Description

    constructor(response: {id: string, name: string, parts: any, description: Description}) {
        this.id = response.id;
        this.name = response.name;
        this.description = response.description;
        this.parts = new Array<Part>();
        for (let part of response.parts) {
            this.parts.push(new Part(part));
        }
    }
}

export { BuildGuide, Part };
