import { Link, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import { BuildGuide } from '../types/build-guide';
import { NoEncryption } from '@mui/icons-material';

function Styling(theme: Theme) {
    return {
        Table: {
            "& .MuiTableCell-root": {
                color: theme.typography.body2.color,
                fontFamily: theme.typography.body2.fontFamily
            },
        },
        PartImage: {
        },
        PartLink: {
            textDecoration: "none"
        },
    }
}

const Img = styled('img')({
    display: 'block',
    maxWidth: '80px',
    maxHeight: '80px',
});

function PartsListTable({build}: any) {
    const theme = useTheme();
    const styling = Styling(theme);
    return (
        <TableContainer component={Paper}>
            <Table aria-label="parts list table" sx={styling.Table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Part</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell></TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Where</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {PartRows(styling, build)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const PriceLink = styled(Link)(({theme}) => ({
    textDecoration: "none",
    color: "green",
    '&:hover': {
        textDecoration: "underline"
    }
}));

function PartRows(styling: any, build: BuildGuide) {
    let partRows = []
    for (let part of build.parts) {
        partRows.push(
            <TableRow key={part.type}>
                <TableCell>{part.type}</TableCell>
                <TableCell><a href={part.affiliate_link} target="_blank"><Img src={part.preview_url}/></a></TableCell>
                <TableCell><Typography variant="body2"><a style={styling.PartLink} href={part.affiliate_link} target="_blank">{part.name}</a></Typography></TableCell>
                <TableCell><PriceLink href={part.affiliate_link} target="_blank">£{part.price/100}</PriceLink></TableCell>
                <TableCell><a href={part.affiliate_link} target="_blank"><Img src={part.affiliate_provider.logoPath}/></a></TableCell>
            </TableRow>
        );
    }

    let price = 0
    build.parts.forEach(part => {price += part.price});
    partRows.push(
        <TableRow key="total">
            <TableCell colSpan={2}><b>Total</b></TableCell>
            <TableCell><b>£{price/100}</b></TableCell>
        </TableRow>
    )
    return partRows
}

export {PartsListTable};
