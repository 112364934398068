import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled, Theme, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

function Styling(theme: Theme): any {
    return {
        Footer: {
            flexGrow: 0, 
            flexShrink: 1, 
            flexBasis: "auto",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            background: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            display: "flex",
            justifyContent: "center"
        },
        FooterBox: {
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: "row",
            maxWidth: "120rem",
            width: "100%",
            paddingLeft: "24px"
        },
        FooterSectionBox: {
            display: 'flex',
            maxWidth: "100%",
            paddingRight: "24px",
            paddingTop: "1rem"
        },
        LogoSmall: {
            maxHeight: "2rem"
        },
        StackHeader: {
            display: "flex",
            color: theme.palette.secondary.main,
        },
        StackItem: {
            display: "flex"
        },
    }
}

const FooterLink = styled(Link)(({theme}) => ({
    '&:hover': {
        color: theme.darkPrimary,
        textDecoration: "none"
    },
    color: theme.palette.secondary.main,
    textDecoration: "none",
}));

const StackHeader = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    padding: theme.spacing(0.25),
    display: 'flex',
    fontFamily: "Oswald"
}));

const StackItem = styled(Typography)(({ theme }) => ({
    ...theme.typography.h6,
    padding: theme.spacing(0.25),
    display: 'flex',
    fontFamily: "Oswald"
}));

function Footer() {
    const theme = useTheme();
    const styling = Styling(theme);
    return (
        <Box sx={styling.Footer}>
            <Box sx={styling.FooterBox}>
                <Box sx={styling.FooterSectionBox}>
                    <Stack spacing={1}>
                        <StackItem><Link to={"/"}><img src="logo-125.png" alt="logo" style={styling.LogoSmall}></img></Link></StackItem>
                        <StackItem><FooterLink to={'/about'}>About</FooterLink></StackItem>
                        <StackItem><FooterLink to={'/affiliate-disclosure'}>Affiliate Disclosure</FooterLink></StackItem>
                        <StackItem><FooterLink to={'/contact-us'}>Contact Us</FooterLink></StackItem>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
};

export default Footer;