import React from 'react';
import Box from '@mui/material/Box';
import { Divider, Theme, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { BuildGuide } from '../types/build-guide';
import { GetBuildGuide } from '../util/aquachoose-client';
import { CircularProgress } from '@mui/material';
import { Container } from '@mui/system';
import { RoutingButton } from './aquachoose-button';
import { PartsListTable } from './parts-list-table';
import Title from './aquachoose-title';

function Styling(theme: Theme): any {
    return {
        PageBox: {
            justifyContent: "center", 
            display: "flex", 
            flexFlow: "column",
            padding: "0 0 2rem 0",
        },
        Title: {
            flex: "0 1 auto",
            paddingBottom: "2rem"
        },
        LoadingBox: {
            display: "flex",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            flex: "1 1 auto",
            paddingTop: "5rem"
        },
        LoadingWheel: {
            flex: "0 1 auto"
        },
        ReturnToBuildsButtonContianer: {
            flex: "0 1 auto", 
            padding: "2rem 2rem 2rem 2rem"
        },
        BuildGuideContainer: {
            flex: "0 1 auto",
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            padding: "1rem 1rem 1rem 1rem",
            maxWidth: "80rem",
            borderRadius: "5px"
        },
        PartDescriptionBox: {
            textAlign: "left",
            width: "100%",
            padding: "1rem 0 1rem 0"
        },
        TableContainer: {
            padding: "1rem 0 1rem 0"
        }
    }
}

function BuildGuideDisplay() {
    const theme = useTheme();
    const styling = Styling(theme);

    const [build, setBuild] = React.useState<BuildGuide>();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [failedToGetBuild, setFailedToGetBuild] = React.useState<boolean>(false);
    const { id }  = useParams<"id">();
    const buildID = id as string

    React.useEffect(() => {
        GetBuildGuide(buildID).then(guide => {
            setBuild(guide);
        })
        .catch(err => {
            setFailedToGetBuild(true);
            console.log("failed to retrieve request: " + err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    if (loading) {
        return (
            <Box sx={styling.LoadingBox}>
                <CircularProgress sx={styling.LoadingWheel}/>
            </Box>
        )
    } 
    else if (!failedToGetBuild && build != undefined) {
        return (
            DisplayGuide(build, styling)
        )
    }
    else {
        return (
            <Error styling={styling}/>
        )
    }
}

function DisplayGuide(build: BuildGuide, styling: any) {
    return (
        <Box sx={styling.PageBox}>
            <Title title={build.name}/>
            <Container sx={{paddingTop: "2rem"}}>
                <Container sx={styling.BuildGuideContainer}>
                    <Typography variant="subtitle1">Build Description</Typography>
                    {DescriptionParts(build, styling)}
                    <Divider sx={{paddingTop: "1rem"}}/>
                    <Typography variant="subtitle1" sx={{paddingTop: "1rem"}}>Parts List</Typography>
                    <Container sx={styling.TableContainer}>
                        <PartsListTable build={build}/>
                    </Container>
                </Container>
                <Container sx={styling.ReturnToBuildsButtonContianer}>
                    <RoutingButton route="/" text="return to builds"/>
                </Container>
            </Container>
        </Box>
    )
}

function DescriptionParts(build: BuildGuide, styling: any) {
    let renderedParts: any = [];
    let parts = new Map<string, string>(Object.entries(build.description.parts));
    renderedParts.push(
        <Box sx={styling.PartDescriptionBox} key={`tank-description`}>
            <Typography variant="subtitle2">TANK</Typography>
            <Typography variant="body1">{parts.get("tank")}</Typography>
        </Box>
    )
    renderedParts.push(
        <Box sx={styling.PartDescriptionBox} key={`filter-description`}>
            <Typography variant="subtitle2">FILTER</Typography>
            <Typography variant="body1">{parts.get("filter")}</Typography>
        </Box>
    )
    renderedParts.push(
        <Box sx={styling.PartDescriptionBox} key={`light-description`}>
            <Typography variant="subtitle2">LIGHT</Typography>
            <Typography variant="body1">{parts.get("light")}</Typography>
        </Box>
    )
    renderedParts.push(
        <Box sx={styling.PartDescriptionBox} key={`heater-description`}>
            <Typography variant="subtitle2">HEATER</Typography>
            <Typography variant="body1">{parts.get("heater")}</Typography>
        </Box>
    )
    return renderedParts;
}

function Error({styling}: any) {
    return (
        <Box sx={styling.PageBox}>
            <Title title="FAILED TO LOAD BUILD GUIDE"/>
            <Container sx={styling.ReturnToBuildsButtonContianer}>
                <RoutingButton route="/" text="return to builds"/>
            </Container>
        </Box>
    )
}

export default BuildGuideDisplay;
