interface AffiliateProvider {
    name: string
    logoPath: string
}

class AmazonProvider implements AffiliateProvider {
    name: string
    logoPath: string

    constructor() {
        this.name = "amazon";
        this.logoPath = "/amazon-logo.png";
    }
}

function CreateAffiliateProvider(affiliate_provider_name: string): AffiliateProvider {
    switch(affiliate_provider_name) {
        case "amazon":
            return new AmazonProvider();
        default:
            throw new Error(`Unknown affiliate provider name: ${affiliate_provider_name}}`)
    }
}

export { AmazonProvider, CreateAffiliateProvider };
export type { AffiliateProvider };
