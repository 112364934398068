import { Box, Container, Theme, Typography, useTheme } from '@mui/material';
import { textAlign } from '@mui/system';
import React from 'react';
import Title from './aquachoose-title';

function Styling(theme: Theme): any {
    return {
        PageBox: {
            justifyContent: "center", 
            display: "flex", 
            flexFlow: "column",
            padding: "0 0 2rem 0",
        },
        AboutContainer: {
            flex: "0 1 auto",
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            marginTop: "2rem",
            padding: "1rem 1rem 1rem 1rem",
            maxWidth: "80rem",
            borderRadius: "5px",
            textAlign: "left"
        },
    }
}

function About() {
    const theme = useTheme();
    const styling = Styling(theme);
    return (
        <Box sx={styling.PageBox}>
            <Title title="ABOUT"/>
            <Container sx={styling.AboutContainer}>
                <Typography variant="body1">
                    <b>Browse priced custom aquarium builds and find where to order all of its parts from</b>
                </Typography>
                <ul>
                    <li>Provides a range of custom aquarium builds carefully assembled by AquaChoose</li>
                    <li>Builds are described in detail and a justification for choosing each part in the build is provided</li>
                    <li>Each build has a total cost associated to it based on the current prices available from online merchants</li>
                    <li>Direct links to merchant's sites where you can order the parts from are given</li>
                </ul>
                <Typography variant="body1">
                    <b>What do you mean by custom aquarium builds?</b>
                </Typography>
                <ul>
                    <li>We are specifically referring to aquariums which are built by purchasing individual parts, for example, 
                        the tank, heater, filter, and light are all brought individually</li>
                    <li>These builds are usually much cheaper overall, however it can be difficult to know which parts are 
                        compatible with one another, whether the parts are reliable, and where to find them. This is where AquaChoose helps</li>
                    <li>This is different to buying an Aquarium kit which comes with all the parts and usually costs more</li>
                </ul>
                <Typography variant="body1">
                    <b>You'll find this site useful if...</b>
                </Typography>
                <ul>
                    <li>You are looking to build a new aquarium which can support a selection of fish and want to know how much 
                        it would cost and where to buy the parts</li>
                    <li>You are looking to build a new aquarium but don't what specifications your budget can afford</li>
                    <li>You are looking for inspiration for your custom aquarium build</li>
                </ul>
                <Typography variant="body1">
                    <b>Why should I trust Aquachoose builds?</b>
                </Typography>
                <ul>
                    <li>AquaChoose has been created by aquarium hobbyists with years of experience with building custom aquariums and love what they do</li>
                    <li>Each build and every part in the build is meticulously researched to ensure compatbility and reliability. Our
                        justification for each part gives you a clear reasoning for choices
                    </li>
                </ul>
            </Container>
        </Box>
    )
}

export default About;