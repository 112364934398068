import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Card, CardContent, CardMedia, CardActionArea, Theme, useTheme, Container, Divider } from '@mui/material';
import { BuildOverview } from '../types/build-overview';
import { ListBuildOverviews } from '../util/aquachoose-client'
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import Title from './aquachoose-title';

function Styling(theme: Theme): any {
    return {
        PageBox: {
            justifyContent: "center", 
            display: "flex", 
            flexFlow: "column"
        },
        BuildGuidesBox: {
            flex: "1 1 auto",
            justifyContent: "center"
        },
        DescriptionContainer: {
            flex: "0 1 auto",
            justifyContent: "center",
            maxWidth: "40rem",
            padding: "1rem 0 1rem 0",
        },
        Grid: {
            width: "100%",
            margin: 0,
            justifyContent: "center"
        },
        BuildOverviewContainer: {
            padding: "2rem 2rem 2rem 2rem",
            maxWidth: "30rem"
        },
        BuildOverview: {
            display: "flex",
            flexFlow: "column",
            background: "primary",
            backgroundColor: theme.palette.primary.main,
            padding: "1rem 1rem 1rem 1rem",
            textAlign: "left",
            borderRadius: "0px",
        },
        CardHeader: {
            padding: "0 0 0rem 0",
        },
        CardContent: {
            padding: "0 0 0 0"
        },
        BuildDescription: {
            flex: "0 1 auto",
        },
        BuildPrice: {
            flex: "0 1 auto"
        },
        BuildPreviewContainer: {
            flex: "1 1 auto",
            paddingTop: "1rem",
        },
        BuildPreview: {
            background: "#FFFFFF",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center"
        },
        BuildPreviewImage: {
            width: "90%",
        },
        LoadingBox: {
            display: "flex",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            flex: "1 1 auto",
            paddingTop: "5rem"
        },
        LoadingWheel: {
            flex: "0 1 auto"
        },
        Divider: {
            backgroundColor: theme.palette.primary.main,
            marginTop: "1rem",
        }
    }
}

function BuildOverviewsList() {
    const theme = useTheme();
    const styling = Styling(theme);
    return (
        <Box sx={styling.PageBox}>
            <Title title="BUILD GUIDES"/>
            <Container style={styling.DescriptionContainer}>
                <Typography variant="body1" sx={{color: theme.darkPrimary}}>
                    It can be hard to know where to start when building your own aquarium. 
                    Check out our aquarium build guides which cover a variety of different 
                    use-cases and budgets.
                </Typography>
                <Divider sx={styling.Divider}></Divider>
            </Container>
            <Box style={styling.BuildGuidesBox}>
                {BuildOverviewsGrid(styling)}
            </Box>
        </Box>
    )
}

function BuildOverviewsGrid(styling: any) {
    const [builds, setBuilds] = React.useState<BuildOverview[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        ListBuildOverviews().then(buildOverviews => {
            setBuilds(buildOverviews); 
        })
        .catch(err => {
            console.log("failed to make request: " + err)
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    if (loading) {
        return (
            <Box sx={styling.LoadingBox}>
                <CircularProgress sx={styling.LoadingWheel}/>
            </Box>
        )
    } else {
        return (
            <Grid2 container spacing={0} sx={styling.Grid}>
                {displayBuildOverviews(builds, styling)}
            </Grid2>
        )
    }
}


const displayBuildOverviews = (buildOverviews: BuildOverview[], styling: any): any[] => {
    let renderedBuilds = [];
    let c = 0;
    for (let build of buildOverviews)  {
        renderedBuilds.push(
        <Grid2 xs={12} sm={12} md={6} xl={4} sx={styling.BuildOverviewContainer} key={c.toString()}>
            <Card>
                <CardActionArea sx={styling.BuildOverview} component={Link} to={`/guide/${build.guide_id}`}>
                    <CardContent sx={styling.CardContent}>
                        <Typography variant="subtitle1" sx={styling.CardHeader}>{build.name}</Typography>
                        <Typography variant="body1" sx={styling.BuildDescription}>{build.tank}</Typography>
                        <Typography variant="body1" sx={styling.BuildDescription}>{build.filter}</Typography>
                        <Typography variant="body1" sx={styling.BuildDescription}>{build.heater}</Typography>
                        <Typography variant="subtitle2" sx={styling.BuildPrice}>£{build.price/100}</Typography>
                        <Box sx={styling.BuildPreviewContainer}>
                            <Box sx={styling.BuildPreview}>
                                <CardMedia component="img" image={build.preview_url} alt="logo" style={styling.BuildPreviewImage}></CardMedia>
                            </Box>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid2>
        );
        c++;
    }
    return renderedBuilds;
};


export default BuildOverviewsList;
