class BuildOverview {
    id:string
    name: string
    tank: string
    filter: string
    heater: string
    price: number
    preview_url: string
    guide_id: string

    constructor(response: {id: string, name: string, tank: string, filter: string, heater: string, price: number, preview_url: string, guide_id: string}) {
        this.id = response.id
        this.name = response.name
        this.tank = response.tank
        this.filter = response.filter
        this.heater = response.heater
        this.price = response.price
        this.preview_url = response.preview_url
        this.guide_id = response.guide_id
    }
}

export { BuildOverview };
