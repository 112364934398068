import { Typography } from '@mui/material';
import React from 'react';

function Styling(): any {
    return {
        Title: {
            flex: "0 1 auto",
            paddingTop: "2rem",
        },
    }
}

function Title({title}: any) {
    const styling = Styling();
    return (
        <Typography variant="h3" sx={styling.Title}>{title}</Typography>
    )
}

export default Title;
