import { Box, Container, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import Title from './aquachoose-title';

function Styling(theme: Theme): any {
    return {
        PageBox: {
            justifyContent: "center", 
            display: "flex", 
            flexFlow: "column",
            padding: "0 0 2rem 0",
        },
        AffiliateDisclosureContainer: {
            flex: "0 1 auto",
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            marginTop: "2rem",
            padding: "1rem 1rem 1rem 1rem",
            maxWidth: "80rem",
            borderRadius: "5px",
            textAlign: "left"
        },
    }
}

function AffiliateDisclosure() {
    const theme = useTheme();
    const styling = Styling(theme);
    return (
        <Box sx={styling.PageBox}>
            <Title title="AFFILIATE DISCLOSURE"/>
            <Container sx={styling.AffiliateDisclosureContainer}>
                <Typography variant="body1">
                    AquaChoose receives compensation through affiliate relationships with merchants listed 
                    on this site. Please know that this in no way affects reviews, content, or this site's
                    opinions of products, manufacturers, or merchants. The mission of AquaChoose is to
                    provide the best aquarium recommendations to our users, regardless of any potential
                    affiliate commissions.<br/>
                    <br/>
                    <b>Callum Pearce</b><br/>
                    <b>Owner / Founder</b><br/>
                    <br/>
                    <i>AquaChoose is a participant in the Amazon Europe S.Ã r.l. Associates Programme, an 
                    affiliate advertising programme designed to provide a means for sites to earn advertising 
                    fees by advertising and linking to Amazon.co.uk.</i>
                </Typography>
            </Container>
        </Box>
    )
}
export default AffiliateDisclosure;