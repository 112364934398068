import React from 'react';
import './App.css';
import Navbar from './components/navbar'
import Footer from './components/footer'
import Box from '@mui/material/Box';
import { Container, createTheme, CssBaseline, responsiveFontSizes, Theme, ThemeProvider, PaletteColorOptions } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import BuildOverviewsList from './components/build-overviews-list';
import BuildGuideDisplay from './components/build-guide';
import ContactUs from './components/contact-us';
import About from './components/about';
import AffiliateDisclosure from './components/affiliate-disclosure';

declare module '@mui/material/styles' {
  interface Theme {
    darkPrimary: string
    darkSecondary: string
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    darkPrimary: string
    darkSecondary: string
  }
}

let theme: Theme = createTheme({
  darkPrimary: "#0B0A07",
  darkSecondary: "#3D3F44",
  palette: {
    primary: {
      main: "#4A6FA5",
    },
    secondary: {
      main: "#DEE1ED",
    },
  },
  typography: {
    h3: {
      fontFamily: [
        "Oswald"
      ].join("'"),
      color: "#0B0A07"
    },
    h5: {
      fontFamily: [
        "Oswald"
      ].join("'"),
      color: "#0B0A07"
    },
    subtitle1: {
      fontFamily: [
        "Oswald"
      ].join("'"),
      fontSize: 24,
      color: "#DEE1ED"
    },
    subtitle2: {
      fontFamily: [
        "Oswald"
      ].join("'"),
      fontSize: 20,
      color: "#DEE1ED"
    },
    body1: {
      fontFamily: [
        "'Roboto Condensed'"
      ].join("'"),
      color: "#DEE1ED",
      fontSize: 19
    },
    body2: {
      fontFamily: [
        "'Roboto Condensed'"
      ].join("'"),
      color: "#0B0A07",
      fontSize: 19
    },
    button: {
      fontSize: 16
    },
  }
})
theme = responsiveFontSizes(theme);

const styling = {
  PageBox: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%"
  },
  ContentBox: {
    background: theme.palette.secondary.main,
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    flexFlow: "column"
  },
  ContentContainer: {
      width: "100%",
      height: "100%",
      maxWidth: "120rem"
  }
};

function App() {
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline enableColorScheme />
    <div className="App">
        <Box style={styling.PageBox}>
          <Navbar/>
          <Box style={styling.ContentBox}>
              <Container style={styling.ContentContainer}>
                <Routes>
                  <Route path="/" element={<BuildOverviewsList/>}></Route>
                  <Route path="guide/:id" element={<BuildGuideDisplay/>}></Route>
                  <Route path="contact-us" element={<ContactUs/>}></Route>
                  <Route path="about" element={<About/>}></Route>
                  <Route path="affiliate-disclosure" element={<AffiliateDisclosure/>}></Route>
                </Routes>
              </Container>
          </Box>
          <Footer/>
        </Box>
    </div>
    </ThemeProvider>
  );
}

export default App;
