import { Box, Container, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import Title from './aquachoose-title';


function Styling(theme: Theme): any {
    return {
        PageBox: {
            justifyContent: "center", 
            display: "flex", 
            flexFlow: "column",
            padding: "0 0 2rem 0",
        },
        ContactUsContainer: {
            flex: "0 1 auto",
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            marginTop: "2rem",
            padding: "1rem 1rem 1rem 1rem",
            maxWidth: "80rem",
            borderRadius: "5px"
        },
    }
}

function ContactUs() {
    const theme = useTheme();
    const styling = Styling(theme);
    return (
        <Box sx={styling.PageBox}>
            <Title title="CONTACT US"/>
            <Container sx={styling.ContactUsContainer}>
                <Typography variant="body1">Please email any enquiries or concerns you have to <b>info@aquachoose.com</b> and we will be in touch shortly.</Typography>
            </Container>
        </Box>
    )
}

export default ContactUs;