import { Theme, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { Link } from 'react-router-dom';

function Styling(theme: Theme): any {
    return {
        LinkButton: {
            fontFamily: "Oswald",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            width: "12rem",
            flex: "0 1 auto",
            ':hover': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
            },
        }
    }
}

type RoutingButtonProps = {
    route: string,
    text: string
}

function RoutingButton({route, text}: RoutingButtonProps) {
    const theme = useTheme();
    const styling = Styling(theme);
    return (
        <Button variant="outlined" sx={styling.LinkButton} component={Link} to={route}>{text}</Button>
    )
}

export { RoutingButton };
