import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Container, Theme, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

function Styling(theme: Theme): any {
    return {
        Navbar: {
            flexGrow: 0, 
            flexShrink: 1, 
            flexBasis: "auto",
            display: "block",
            width: 1
        },
        AppBar: {
            position: "static"
        },
        Toolbar: {
            background: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            display: "flex",
            justifyContent: "center"
        },
        Logo: {
            maxHeight: "2rem",
        },
        NavbarContentBox: {
            display: "flex",
            maxWidth: "120rem",
            width: "100%",
        },
        LogoBox: {
            display: "flex"
        }
    }
}

function Navbar() {
    const theme = useTheme<Theme>();
    const styling = Styling(theme);
    return (
        <Box sx={styling.Navbar}>
            <AppBar sx={styling.AppBar}>
                <Toolbar sx={styling.Toolbar}>
                    <Box sx={styling.NavbarContentBox}>
                        <Box sx={styling.LogoBox} component={Link} to={"/"}>
                            <img src="logo-125.png" alt="logo" style={styling.Logo}></img>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
};

export default Navbar;